// Removes blank space at the bottom of the Timeline component
.timeline {
  :global(.ant-timeline-item-last) {
    padding-bottom: 0 !important;

    :global(.ant-timeline-item-content) {
      min-height: auto !important;
    }
  }
}
