.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin: 0 -10px;
  padding: 8px 10px;
  border-radius: 12px;
}

.row:hover {
  background-color: #fafafa;
}

:global(.dark-content) .row:hover {
  background-color: #1d1d1d;
}

.path {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  &::before {
    content: "";
    width: 8px;
    height: 2px;
    background-color: black;
    position: absolute;
    left: -12px;
  }
}

:global(.dark-content) .path::before {
  background-color: white;
}

@media screen and (max-width: 576px) {
  .row {
    padding: 8px 4px;
    margin: 0;
  }
}
