.sha256 {
  width: 300px;
}

.processName {
  width: 250px;
  margin-bottom: 0;
  word-break: "break-all";
}

@media screen and (min-width: 1600px) {
  .processName {
    width: 90%;
  }
}

@media screen and (min-width: 1800px) {
  .sha256 {
    width: 500px;
  }
}
