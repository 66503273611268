.listItem {
  display: flex;
  cursor: pointer;
  &:hover {
    background-color: #fafafa;
  }
}

:global(.dark-content) .listItem:hover {
  filter: brightness(75%);
  background-color: unset;
}
