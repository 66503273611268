.postTitle {
  overflow: hidden;
  white-space: nowrap;
  max-width: 0;

  a {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
