@media screen and (max-width: 700px) {
  .rangePicker {
    :global(.ant-picker-panel:last-child) {
      display: none !important;
    }

    :global(.ant-picker-header-next-btn) {
      visibility: initial !important;
    }

    :global(.ant-picker-header-super-next-btn) {
      visibility: initial !important;
    }
  }
}
