.overlay {
  // Ensures the overlay matches the input width by removing padding
  width: calc(100% - 22px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 11px;
  padding-top: 4px;
  padding-bottom: 4px;
  border: 1px solid transparent;
  white-space: pre;
  pointer-events: none;
  overflow: hidden;
  text-overflow: clip;
}
