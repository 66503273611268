.content {
  all: unset;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
  white-space: nowrap;
  pointer-events: none;
  font-size: 14px;
  min-width: 0;
}

.copy {
  color: #1677ff;
  &:hover {
    color: #69b1ff;
  }
}
