.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.spinner {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: spinner 1.1s infinite linear;
  animation: spinner 1.1s infinite linear;
  border-top: 12px solid #1677ff;
  border-right: 12px solid #1677ff;
  border-bottom: 12px solid #1677ff;
  border-left: 12px solid transparent;

  &:after {
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }
}

@keyframes spinner {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
