.card {
  padding: 0.8rem;
  margin: 0.5rem 0;

  :global(.ant-card-head-title) {
    white-space: unset !important;
  }
}

.tag {
  margin: 0 0.5rem;
}

.fields {
  font-size: 0.8rem;
  font-weight: normal;
  margin-right: 0.75rem;
}

.title {
  margin-bottom: 0 !important;
}

.download {
  padding: 0;
  margin-top: 0.75rem;
}

.empty {
  flex: 1;
  padding: 48px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
