.result {
  path[fill="#A26EF4"] {
    fill: #1668dc;
  }
}

:global(.dark-content) .result {
  svg {
    filter: brightness(90%);
  }

  path[fill="#E4EBF7"] {
    fill: #1f1f1f;
  }
}

@media screen and (max-width: 390px) {
  .result {
    padding-left: 10px;
    padding-right: 10px;
  }
}
