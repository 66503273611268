.cardStyle {
  > div {
    padding: 8px 16px !important;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    &:after {
      display: none !important;
    }
    &:before {
      display: none !important;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin: 0 16px;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.sider {
  display: none;
}

@media screen and (min-width: 992px) {
  .sider {
    display: block;
  }
}
